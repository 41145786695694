import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import Layout from '../components/Layout';
import Content, {HTMLContent} from '../components/Content';
import PageHeading from "../components/PageHeading";

export const AboutPageTemplate = ({title, content, contentComponent}) => {
    const PageContent = contentComponent || Content;

    return (
        <>
            <PageHeading heading={title} className="title is-size-3 is-size-4-touch is-size-4-mobile"/>
            <section className="section">
                <div className="container content">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <PageContent className="content has-text-justified article-content" content={content}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

AboutPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const AboutPage = ({data}) => {
    const {markdownRemark: post} = data;

    return (
        <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
            <AboutPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    )
}

AboutPage.propTypes = {
    data: PropTypes.object.isRequired
}

export default AboutPage;

export const aboutPageQuery = graphql`
    query AboutPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                description
            }
        }
    }
`;
